<script setup lang="ts">
import { EvLogo, EvLogoAppearance } from '@kreait/web-components'
import AppContainer from './AppContainer.vue'

const i18n = useI18n()

const navigation = {
  company: [
    {
      name: i18n.t('app-footer.company.about-us'),
      to: '/about-us',
    },
    {
      name: i18n.t('app-footer.company.careers'),
      to: '/careers',
    },
    {
      name: i18n.t('app-footer.company.blog'),
      to: '/blog',
    },
    {
      name: i18n.t('app-footer.support.glossary'),
      to: '/glossary',
    },
  ],
  legal: [
    {
      name: i18n.t('app-footer.legal.imprint'),
      to: '/imprint',
    },
    {
      name: i18n.t('app-footer.legal.privacy'),
      to: '/privacy',
    },
    {
      name: i18n.t('app-footer.legal.whistleblower'),
      to: '/whistleblower',
    },
  ],
  socials: [
    {
      name: i18n.t('app-footer.socials.instagram'),
      href: 'https://www.instagram.com/elvah_de/?hl=de',
    },
    {
      name: i18n.t('app-footer.socials.facebook'),
      href: 'https://www.facebook.com/elvahDE/',
    },
    {
      name: i18n.t('app-footer.socials.linkedIn'),
      href: 'https://de.linkedin.com/company/elvah',
    },
    {
      name: i18n.t('app-footer.socials.twitter'),
      href: 'https://twitter.com/try_elvah',
    },
  ],
}

const currentYear = ref(new Date().getFullYear())
</script>

<template>
  <footer class="grow bg-teal-900">
    <AppContainer class="pb-6 pt-12">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div
          class="flex flex-col items-center space-y-8 lg:items-start xl:col-span-1"
        >
          <nuxt-link to="/" class="text-white-pure">
            <EvLogo
              class="h-auto w-12 pb-4 lg:mr-auto"
              :type="EvLogoAppearance.WITH_NAME"
            />
          </nuxt-link>
        </div>
        <div
          class="mx-8 mt-12 md:mx-0 xl:col-span-2 xl:mt-0"
        >
          <div class="grid grid-cols-2 md:grid-cols-3 md:gap-8">
            <div>
              <h3 class="text-xs capitalize tracking-wider text-gray-dark">
                {{ $t('app-footer.company.headline') }}
              </h3>
              <ul role="list" class="mt-4 space-y-1">
                <li v-for="item in navigation.company" :key="item.name">
                  <nuxt-link
                    :to="item.to"
                    class="text-xs font-semibold text-white-pure hover:text-white-off"
                  >
                    {{ item.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-xs capitalize tracking-wider text-gray-dark">
                {{ $t('app-footer.legal.headline') }}
              </h3>
              <ul role="list" class="mt-4 space-y-1">
                <li v-for="item in navigation.legal" :key="item.name">
                  <nuxt-link
                    :to="item.to"
                    class="text-xs font-semibold text-white-pure hover:text-white-off"
                  >
                    {{ item.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-xs capitalize tracking-wider text-gray-dark">
                {{ $t('app-footer.socials.headline') }}
              </h3>
              <ul role="list" class="mt-4 space-y-1">
                <li v-for="item in navigation.socials" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-xs font-semibold text-white-pure hover:text-white-off"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 border-t border-gray-dark/20  pt-4">
        <p class="px-6 text-xs text-gray-dark md:px-0 xl:text-left">
          {{ $t('app-footer.legal.copyright', { year: currentYear }) }}
        </p>
      </div>
    </AppContainer>
  </footer>
</template>
