<script setup lang="ts">
import { ref } from 'vue'
import { EvLogo, EvLogoAppearance } from '@kreait/web-components'
import IconHumburger from 'virtual:icons/elvah/hamburger'
import IconClose from 'virtual:icons/elvah/x'
import { useWindowScroll, useWindowSize } from '@vueuse/core'
import { Dialog, DialogPanel } from '@headlessui/vue'
import AppContainer from './AppContainer.vue'

interface Props {
  isDark?: boolean
}

withDefaults(defineProps<Props>(), {
  isDark: false,
})

const mobileMenuOpen = ref(false)

const { t } = useI18n()

const { y: scrollHeight } = useWindowScroll()
const { height: windowHeight } = useWindowSize()

const showMenuWhenScrolled = computed(() => {
  return scrollHeight.value > windowHeight.value
})

const navigation = ref([
  {
    label: t('app-header.menu.about-us'),
    to: '/about-us',
  },
  {
    label: t('app-header.menu.blog'),
    to: '/blog',
  },
  {
    label: t('app-header.menu.glossary'),
    to: '/glossary',
  },
  {
    label: t('app-header.menu.careers'),
    to: '/careers',
  },
])
</script>

<template>
  <AppContainer
    as="div"
    class="z-20 grow-0 text-teal-primary"
    :outer-class="`border-b ${isDark ? 'border-primary-500' : 'border-gray-light'}`"
    :class="[
      showMenuWhenScrolled ? 'animate-navigation fixed drop-shadow-md' : 'absolute',
      isDark ? 'bg-teal-900' : 'bg-gray-50',
    ]
    "
  >
    <header>
      <nav class="mx-auto flex max-w-7xl items-center justify-between py-6" aria-label="Global">
        <div class="flex lg:flex-1">
          <nuxt-link :to="{ path: '/' }">
            <span class="sr-only">{{ $t("app-header.menu.screen-reader.elvah") }}</span>
            <EvLogo
              class="h-auto w-14"
              :class="[
                isDark ? 'text-gray-50' : 'text-teal-800',
              ]"
              :type="EvLogoAppearance.WITH_NAME"
            />
          </nuxt-link>
        </div>
        <div class="flex sm:hidden">
          <button
            type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">{{ $t("app-header.menu.screen-reader.open") }}</span>
            <IconHumburger
              class="size-8" :class="[
                isDark ? 'text-gray-50' : 'text-teal-800',
              ]"
              aria-hidden="true"
            />
          </button>
        </div>
        <div class="hidden sm:flex sm:gap-x-6">
          <nuxt-link
            v-for="(item, index) in navigation"
            :key="index"
            :to="item.to"
            class="text-sm font-semibold leading-6"
            :class="[
              isDark ? 'text-white-pure hover:text-gray-200' : 'text-teal-700 hover:text-teal-900',
            ]"
          >
            {{ item.label }}
          </nuxt-link>
        </div>
      </nav>
      <ClientOnly>
        <Dialog as="div" class="sm:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
          <div class="fixed inset-0 z-10" />
          <DialogPanel
            class="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-gray-50 px-4 py-6"
          >
            <div class="flex items-center justify-between">
              <nuxt-link :to="{ path: '/' }">
                <span class="sr-only">{{ $t("app-header.menu.screen-reader.elvah") }}</span>
                <EvLogo class="h-auto w-14 text-teal-800" :type="EvLogoAppearance.WITH_NAME" />
              </nuxt-link>
              <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                <span class="sr-only">{{ $t("app-header.menu.screen-reader.close") }}</span>
                <IconClose class="size-8" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-10 flow-root">
              <div class="-my-6">
                <div class="space-y-2 divide-y divide-gray-200 py-6">
                  <nuxt-link
                    v-for="(item, index) in navigation"
                    :key="index"
                    :to="item.to"
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    @click="mobileMenuOpen = false"
                  >
                    {{
                      item.label
                    }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </ClientOnly>
    </header>
  </AppContainer>
</template>

<style lang="scss" scoped>
@-webkit-keyframes navanimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes navanimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.animate-navigation {
  -webkit-animation: navanimation 0.3s ease-in-out 0s 1 normal none running;
  animation: navanimation 0.3s ease-in-out 0s 1 normal none running;
}

.router-link-active {
  @apply text-green-vibrant;
}
</style>
